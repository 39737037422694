import { View, Text, Link } from '@/components'
import { StylesOf, useI18N } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'
import { Settings } from '@/app'

export type FooterNavigateProps = {
  variantStyles?: StylesOf<FooterComposition>
}

type NavigateLink = {
  text: string
  route?: AppRoute
  to?: string
}

function Column({ links, title, variantStyles }) {
  const renderLink = (link, index) => (
    <Link
      key={index + 'footer-link'}
      route={link?.route}
      to={link?.to}
      openNewTab={!!link?.to}
      text={link?.text}
      css={variantStyles.navigateLink}
    />
  )

  return (
    <View css={variantStyles.navigateColumn}>
      <Text css={variantStyles.navigateTitle} text={title} />
      {links.map(renderLink)}
    </View>
  )
}

export const FooterNavigate = (props: FooterNavigateProps) => {
  const { variantStyles } = props
  const { t } = useI18N()

  const links: Record<string, NavigateLink[]> = {
    pages: [
      { text: t('footer.pages.home'), route: 'Home' },
      { text: t('footer.pages.about'), route: 'About' },
      { text: t('footer.pages.contact'), route: 'Contact' },
    ],
    info: [
      { text: t('footer.info.help'), to: 'http://docs.codeleap.co.uk/' },
      { text: t('footer.info.pricing'), to: 'http://docs.codeleap.co.uk/' },
      { text: t('footer.info.blog'), to: 'http://docs.codeleap.co.uk/' },
      { text: t('footer.info.about'), to: 'http://docs.codeleap.co.uk/' },
    ],
    legal: [
      { text: t('footer.legal.privacy'), to: Settings.ContactINFO.PrivacyPolicy },
      { text: t('footer.legal.terms'), to: Settings.ContactINFO.TermsAndConditions },
    ],
  }

  return (
    <View css={variantStyles.navigateWrapper}>
      <Column
        variantStyles={variantStyles}
        title={t('footer.pages.title')}
        links={links?.pages}
      />
      <Column
        variantStyles={variantStyles}
        title={t('footer.info.title')}
        links={links?.info}
      />
      <Column
        variantStyles={variantStyles}
        title={t('footer.legal.title')}
        links={links?.legal}
      />
    </View>
  )
}
