import { AppIcon, AppImages, assignTextStyle, MOBILE_BREAKPOINT, StyleConstants, variantProvider } from '@/app'
import { CenterWrapper, JobCards, Text } from '@/components'
import { useI18N } from '@codeleap/common'

export function ProvidedServicesSection() {
  const { t } = useI18N()

  const cards = [
    { title: t('jobTypes.eletrical'), image: AppImages.Section2Eletrical, icon: 'flash' as AppIcon },
    { title: t('jobTypes.gardening'), image: AppImages.Section2Gardening, icon: 'leaf' as AppIcon },
    { title: t('jobTypes.cleaning'), image: AppImages.Section2Cleaning, icon: 'brush' as AppIcon },
    { title: t('jobTypes.handyman'), image: AppImages.Section2Handyman, icon: 'hammer' as AppIcon },
    { title: t('jobTypes.plumbing'), image: AppImages.Section2Plumbing, icon: 'pipe' as AppIcon },
    { title: t('jobTypes.other'), image: AppImages.Section2Others, icon: 'misc' as AppIcon },
  ]

  return (
    <CenterWrapper
      //@ts-ignore
      id='section2'
      variants={['sectionsPaddings']}
      styles={{
        wrapper: styles.centerWrapper,
        innerWrapper: styles.centerInnerWrapper,
      }}>
      <Text css={styles.text} text={t('homeSections.providedServices.title')} />
      <JobCards cards={cards} />
    </CenterWrapper>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    centerWrapper: {
      ...StyleConstants.orangeGradient,
    },
    centerInnerWrapper: {
      ...theme.presets.column,
      ...theme.presets.center,
    },

    text: {
      ...assignTextStyle('h1')(theme).text,
      color: theme.colors.neutral1,
      ...theme.presets.fullWidth,
      ...theme.presets.textCenter,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...assignTextStyle('h2')(theme).text,
        color: theme.colors.neutral1,
      },
    },
  }),
  true,
)
