import { TypeGuards, useI18N, useMemo } from '@codeleap/common'
import { View, Button, Tooltip, Icon, Text, Link } from '@/components'
import { I18NDictionary } from '@/app/i18n'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { assignTextStyle, MOBILE_BREAKPOINT, variantProvider } from '@/app'
import { useIsMobile } from '@/utils'

export const languageOptions = Object.entries(I18NDictionary).map(([key, value]) => ({
  label: value.__LABEL,
  value: key,
}))

const _Link = props => <Link {...props} type='i18n' style={styles.link} />

export function LanguageOptions() {
  const { locale, setLocale } = useI18N()
  const { originalPath } = useI18next()
  const isMobile = useIsMobile()

  return (
    <View variants={['gap:1', 'column']} responsiveVariants={{ [MOBILE_BREAKPOINT]: ['gap:2'] }}>
      {languageOptions.map(item => {
        const selected = item.value === locale

        return (
          <Button
            component={_Link}
            debugName='language selector'
            key={item.label}
            to={originalPath}
            variants={['language', selected && 'language:selected']}
            language={item.value}
            onClick={() => setLocale(item?.value)}
          >
            <Text
              variants={['p3', 'color:neutral8']}
              responsiveVariants={{ [MOBILE_BREAKPOINT]: ['p1', selected && 'color:neutral1'] }}
              style={styles.text}
              text={item.label}
            />
            {selected ? (
              <Icon
                name={selected ? 'check' : null}
                debugName='icon check'
                style={{ ...styles.icon, color: isMobile ? '#fff' : '#888888' }}
              />
            ) : null}
          </Button>
        )
      })}
    </View>
  )
}

export const LanguageSelector = () => {
  const { locale } = useI18N()

  const selectedLocale = useMemo(() => {
    const selectedOption = languageOptions.find(l => l.value === locale)
    return TypeGuards.isNil(selectedOption) ? languageOptions.find(l => l.value === 'en-GB').label : selectedOption.label
  }, [locale])

  return (
    <Tooltip
      debugName='Language selector'
      openOnHover={false}
      openOnPress
      closeOnClickOutside
      styles={{ content: styles.tooltipContent }}
      content={<LanguageOptions />}
    >
      <View css={styles.innerWrapper}>
        <Icon name='globe' debugName='icon globe' style={styles.icon} />
        <Text variants={['p3', 'color:neutral9']} style={styles.text} text={selectedLocale} />
        <Icon name={'chevron-down'} debugName='icon chevron' style={styles.icon} />
      </View>
    </Tooltip>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    tooltipContent: {
      zIndex: 10,
      width: theme.spacing.value(17.5),
      borderRadius: theme.borderRadius.small,
      ...theme.effects.light,
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.padding(1.25),
    },
    innerWrapper: {
      cursor: 'pointer',
      ...theme.spacing.gap(1),
      backgroundColor: theme.colors.neutral2,
      width: theme.spacing.value(17.5),
      height: theme.values.itemHeight.default,
      borderRadius: theme.borderRadius.small,
      ...theme.presets.center,
    },
    link: {
      textDecoration: 'none',
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral10,
    },
    icon: {
      ...theme.sized(2.5),
      color: theme.colors.neutral8,
    },
    text: {
      fontWeight: 500,
    },
  }),
  true,
)
